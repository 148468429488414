import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';

class CardItem extends React.Component {

  render() {
    const { title, valueDisplay, color, onClick } = this.props;
    return (
      <Card sx={{ minWidth: 150, maxWidth: 150 }}>
        <CardActionArea onClick={onClick} disabled={!onClick}>
          <CardContent>
            <Typography sx={{ fontSize: 12, height: 50 }} color="text.secondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h3" textAlign="center" sx={{ color: color || 'warning.main'}}>
              {valueDisplay}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

CardItem.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  valueDisplay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
}

export default CardItem;
