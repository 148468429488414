import React from 'react';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EditableList from '../../containers/EditableList/index.js';
import { dateTimeEnToDateTimeFr, dateEnToDateFr } from '../../utils/ColumnFormatter';
import styles from './styles.js';
import InfoModal from './InfoModal/index.js';
import Ajax from "../../utils/Ajax/index.js";
import Notify from "../../components/base/Notify/index.js";
import { Tooltip, IconButton, Dialog, DialogContent } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Download from '../../utils/Download/index.js';

class OrderMKM extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshToken: 0,
      open: false,
      reportsModal: false,
      infoItem: {},
      infoModalOpen: false,
      syncStatusModal: false,
      syncStatus: { progress: 0.00, orderStatus: "" }
    };
    this.renderReportsButton = this.renderReportsButton.bind(this);
  }


  async handleReportExports(row) {
    const res = await Ajax.get({
      url: "api/reports/" + row.id + "/export",
      token: true,
    });
    if (res.type === 'success' && res.content && res.content !== 'false') {
      Download(res.content, `export-report-order-amazon-${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`, 'csv');
      Notify.success('Opération effectuée avec succès');
    }
  }

  renderReports() {
    const { classes } = this.props;
    const { reportsModal, refreshToken } = this.state

    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={() => this.setState({ reportsModal: !reportsModal })} open={reportsModal}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <EditableList
              refreshToken={refreshToken}
              title="Rapports de synchronisation"
              route="reports"
              rowKey="id"
              maxSize="sm"
              defaultParams={{
                order: 'report_creation_date',
                direction: 'DESC',
              }}
              disabledHashedState
              queryStringEnrich={() => ({ reportType: "Order MKM" })}
              columns={[
                { field: 'id', headerName: 'ID', width: 60 },
                {
                  field: '__actions_export', headerName: 'Export', width: 90, sortable: false, renderCell: ({ row }) => (
                    <Tooltip title="Exporter la data">
                      <span>
                        <IconButton onClick={() => this.handleReportExports(row)}>
                          <SaveAltIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                },
                { field: 'report_data', headerName: 'Data', width: 200 },
                { field: 'report_success_total', headerName: 'Réussite', width: 120, sortable: false },
                { field: 'report_failed_total', headerName: 'Echec', width: 80, sortable: false },
                { field: 'report_pending_total', headerName: 'En attente', width: 120, sortable: false },
                { field: 'report_creation_date', headerName: 'Date de création', width: 200, valueFormatter: dateTimeEnToDateTimeFr },
                { field: 'report_type', headerName: 'Type', width: 200 },
              ]}
              filterParams={[
                { key: 'id', label: 'ID' },
                { key: 'reportCreationDateMin', label: 'Date de création min', type: 'date' },
                { key: 'reportCreationDateMax', label: 'Date de création max', type: 'date' },
              ]}
              searchPlaceholder="ID"
              allowEdit={false}
              allowAdd={false}
              allowDelete={false}
              allowExport={false}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
  renderReportsButton() {
    const { reportsModal } = this.state;
    return (
      <Tooltip key="Rapport" title="Voir les rapports">
        <span>
          <IconButton color="secondary" onClick={() => this.setState({ reportsModal: !reportsModal })}>
            <AssessmentIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }
  renderInfoPanel() {
    const { infoItem, infoModalOpen } = this.state;
    if (!infoModalOpen)
      return null;
    return <InfoModal params={infoItem} handleOpen={() => this.setState({ infoModalOpen: !infoModalOpen })} open={infoModalOpen} />
  }

  statusRenderCell(params) {
    const style = {};
    if (params.value === 'paid')
      style.color = 'red';
    if (params.value === 'cancelled')
      style.color = 'blue';
    if (params.value === 'sent')
      style.color = 'green';
    return <span style={style}>{params.value}</span>;
  }

  render() {
    const { classes } = this.props;
    const { refreshToken } = this.state;
    return (
      <div className={classes.wrapper}>
        <EditableList
          refreshToken={refreshToken}
          title="Card Market - Commandes"
          route="order_mkm"
          rowKey="id"
          defaultParams={{
            mkm_status: "paid",
            order: 'mkm_last_update_date',
            direction: 'DESC',
          }}
          maxSize="xl"
          columns={[
            {
              field: '__actions_info', headerName: '', width: 60, sortable: false, renderCell: ({ row }) => (
                <Tooltip title="Voir la ligne">
                  <span>
                    <IconButton onClick={() => this.setState({ infoModalOpen: true, infoItem: row })}>
                      <InfoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )
            },
            { field: 'id', headerName: 'ID', width: 90 },
            { field: 'mkm_order_id', headerName: 'MKM Order ID', width: 200 },
            { field: 'order_id', headerName: 'Order ID', width: 80 },
            { field: 'mkm_status', headerName: 'Status', width: 100, renderCell: this.statusRenderCell },
            { field: 'mkm_last_update_date', headerName: 'Last Update', width: 140, valueFormatter: dateEnToDateFr },
            { field: 'mkm_shipping_address', headerName: 'Lieux d\'envoi', width: 200 },
            { field: 'mkm_shipment_method', headerName: 'Méthode d\'envoi', width: 200 },
            { field: 'mkm_order_total', headerName: 'Prix Total', width: 120, sortable: false },
            { field: 'mkm_number_items', headerName: 'Nombre d\'article', width: 80, sortable: false },
            { field: 'is_presale', headerName: 'Précommande', width: 120, valueFormatter: ({ value }) => value >= 1 ? 'Oui' : 'Non' },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'mkm_order_id', label: 'MKM order ID' },
            { key: 'mkm_status', label: 'MKM status', type: 'select', options: ['paid', 'sent', 'cancelled'], variant: 'standard' },
          ]}
          actions={[
            this.renderReportsButton(),
          ]}
          searchPlaceholder="Filter par Nom"
          allowEdit={false}
          allowAdd={false}
          allowDelete={false}
        />

        {this.renderReports()}
        {this.renderInfoPanel()}
      </div>
    );
  }
}

OrderMKM.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default withStyles(styles)(OrderMKM);
