import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fr';
import CssBaseline from '@mui/material/CssBaseline';
import isNil from 'lodash/isNil';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Device from '../../utils/Device';
import Prompt from '../../components/base/Prompt';

import Theme from '../../theme';
import Router from '../Router';

import Config from '../../config';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.handleUser = this.handleUser.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);

  }
  
  /** SETUP */
  componentDidMount() {
    const html = document.querySelector('html');
    if (isNil(Device.mobile())) {
      html.className += 'desktop';
    } else {
      html.className += 'mobile';
    }
  }
  /** HANDLERS */
  handleUser(user) {
    this.setState({ user });
  }


  handleLogOut() {
    const { TOKEN_KEY } = Config;
    window.sessionStorage.removeItem(TOKEN_KEY);
    this.handleUser(Config.DEFAULT_USER);
  }

  render() {
    return (
      <ThemeProvider theme={Theme} >
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <BrowserRouter getUserConfirmation={Prompt.getUserConfirmation}>
            <Router
              {...this.state}
              onUser={this.handleUser}
              onLogout={this.handleLogOut}
            />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
}

Main.propTypes = {

}

export default Main;