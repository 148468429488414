import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EditableList from '../../containers/EditableList/index.js';
import { renderPreviewCell } from '../../utils/ColumnFormatter';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';
import { Button } from '@mui/material';

class SingleMKM extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshToken: 0,
      gamesId: [],
      gamesName: {},
    };
    this.booleanLabel = {'': 'Tous', '0': 'Non', '1': 'Oui'};
  }

  async componentDidMount() {
    const result = await Ajax.get({ url: 'api/singles_mkm/options', token: true});
    const gamesName = {};
    const gamesId = [''];
    result.games.forEach(game => {
      gamesId.push(game.mkm_game_id);
      gamesName[game.mkm_game_id] = game.name_fr;
    })
    this.setState({ gamesName, gamesId });
  }

  nameFormateur({ row }) {
    return (
      <>
        <div style={{ fontSize: 15, lineHeight: 1 }}>{row.name_fr}</div >
        <div style={{ fontSize: 12, lineHeight: 1 }}>{row.name_en}</div >
      </>
    );
  }

  getOptionLabelType(opt) {
    if (opt === 'unite') {
      return 'Unité';
    } else if (opt === 'sealed') {
      return 'Scellé';
    }
    return 'Tout';
  }

  renderMkmPreviewCell(attr) {
    let value = '';
    if (attr.value) {
      const { host, protocol } = window.location;
      value = `${protocol}//${host}/${attr.value}`;
    }
    return renderPreviewCell({ ...attr, value });
  }

  renderWebsiteCell({ row }) {
    if (row?.id) {
      let url = "https://www.cardmarket.com" + row?.website;
      return <Button component="a" href={url} variant="text" color="inherit" target="_blank">{row?.id}</Button>;
    }
    return '';
  }

  render() {
    const { classes } = this.props;
    const { refreshToken, gamesId, gamesName } = this.state;
    return (
      <div className={classes.wrapper}>
        <EditableList
          refreshToken={refreshToken}
          title="Card Market - Base de données des produits"
          route="singles_mkm"
          rowKey="id"
          maxSize="xl"
          columns={[
            { field: 'id', headerName: 'ID', width: 90, renderCell: this.renderWebsiteCell },
            { field: 'image', headerName: 'Aperçu', width: 90, renderCell: this.renderMkmPreviewCell },
            { field: 'game_id', headerName: 'Numéro de jeu', width: 80 },
            { field: 'expansion_id', headerName: 'Numéro d\'extension', width: 80 },
            { field: 'name_fr', headerName: 'Nom', width: 300, renderCell: this.nameFormateur },
            { field: 'game_name_fr', headerName: 'Nom du jeu', width: 300, sortable: false },
            { field: 'expansion_name_fr', headerName: 'Nom de l\'extension', width: 300, sortable: false },
            { field: 'card_number', headerName: 'Numéro de carte', width: 120 },
            { field: 'rarity', headerName: 'Rareté', width: 120 },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'game_id', label: 'Jeux', type: 'select', options: gamesId, getOptionLabel: id => id ? gamesName[id] : 'Tous' },
            { 
              key: 'expansion_id', label: 'Extension', type: 'autocomplete', route: 'api/expansions_mkm', 
              searchKey: 'search', idKey: 'id', labelKey: 'name_fr', getOptionLabel: item => !item ? '' : `[${item.abbreviation}] ${item.name_fr}`
            },
            { key: 'name_fr', label: 'Nom FR' },
            { key: 'card_number', label: 'Numéro de carte' },
            { key: 'rarity', label: 'Rareté' },
            { key: 'type', label: 'Type', type: 'select', options: ['', 'unite', 'sealed'], variant: 'standard', getOptionLabel: this.getOptionLabelType },  
          ]}
          actions={[]}
          searchPlaceholder="Filtrer par Nom FR/EN"
          allowEdit={false}
          allowAdd={false}
          allowDelete={false}
          allowExport={false}
        />
      </div>
    );
  }
}

SingleMKM.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default withStyles(styles)(SingleMKM);
