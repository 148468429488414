import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { withStyles } from '@mui/styles';
import { Divider, Stack, Typography, Skeleton, Grid } from '@mui/material';
import styles from './styles.js';
import CardItem from './CardItem/index.js';
import Ajax from "../../utils/Ajax";
//import DoughnutItem from './DoughnutItem/';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      amazon: null,
      cardmarket: null,
    };
  }

  async componentDidMount() {
    const [amazonRes, mkmRes] = await Promise.all([
      Ajax.get({ url: `api/dashboard/amazon`, token: true }),
      Ajax.get({ url: `api/dashboard/mkm`, token: true }),
    ]);
    if (amazonRes.type === 'success' && mkmRes.type === 'success') {
      delete amazonRes.type;
      delete mkmRes.type;
      this.setState({ amazon: amazonRes, cardmarket: mkmRes });
    }
  }

  statedLink(route, filters) {
    const id = btoa(uniqueId('hash_'));
    const hash = btoa(JSON.stringify({ filters, page: 0, columnsVisibility: {} }));
    window.sessionStorage.setItem(id, hash);
    this.props.redirect(`/${route}?hashedState=${id}`);
  }

  toUnshippedLink() {
    this.statedLink('order-amazon', {
      amazon_order_id: '', 
      amazon_status: 'Unshipped',
      direction: 'DESC',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  toSellLink() {
    this.statedLink('products-amazon', {
      amazon_state_status: "Vérifié", 
      amazon_sync: '0',
      direction: 'ASC',
      stockStart: '1',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  toVerifedLink() {
    this.statedLink('products-amazon', {
      amazon_state_status: "À vérifier", 
      amazon_sync: '0',
      direction: 'ASC',
      visibility: '1',
      amazon_lock_sync: '0',
      stockStart: '1',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }
  

  toMultipleLink() {
    this.statedLink('products-amazon', {
      amazon_state_status: "Matchs multiple",
      visibility: '1',
      amazon_lock_sync: '0',
      amazon_sync: '0',
      direction: 'ASC',
      stockStart: '1',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  toLockLink() {
    this.statedLink('products-amazon', {
      amazon_lock_sync: 1,
      direction: 'ASC',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  toPendingLink() {
    this.statedLink('products-amazon', {
      amazon_state_status: "En attente", 
      direction: 'ASC',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  mkmToUnshippedLink() {
    this.statedLink('order-mkm', {
      mkm_order_id: '',
      mkm_status: 'paid',
      direction: 'DESC',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  mkmToSellLink() {
    this.statedLink('products-mkm', {
      mkm_state_status: "Vérifié",
      mkm_sync: '0',
      direction: 'ASC',
      stockStart: '1',
      visibility: '1',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  mkmToVerifedLink() {
    this.statedLink('products-mkm', {
      mkm_state_status: "À vérifier",
      mkm_sync: '0',
      direction: 'ASC',
      visibility: '1',
      mkm_lock_sync: '0',
      stockStart: '1',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }


  mkmToMultipleLink() {
    this.statedLink('products-mkm', {
      mkm_state_status: "Matchs multiple",
      visibility: '1',
      mkm_lock_sync: '0',
      mkm_sync: '0',
      direction: 'ASC',
      stockStart: '1',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  mkmToLockLink() {
    this.statedLink('products-mkm', {
      mkm_state_status: 1,
      direction: 'ASC',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }

  mkmToPendingLink() {
    this.statedLink('products-mkm', {
      mkm_state_status: "En attente",
      direction: 'ASC',
      limit: 25,
      offset: 0,
      order: 'id',
      search: ''
    });
  }
  

  render() {
    const { classes } = this.props;
    const { amazon, cardmarket } = this.state;
    return (
      <div className={classes.wrapper} >
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.6rem' } }}>Tableau de bord</Typography>
        <Divider/>
        <Stack mt={3} direction="column" spacing={5} >
          <Grid container spacing={5}>
            <Grid item xs={12} sm="auto">
              <Stack direction="column" spacing={2}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '0.9rem', sm: '0.9rem', md: '1.4rem' } }}>Commandes Amazon</Typography>
                <Grid container spacing={1} sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                  <Grid item >{amazon ? <CardItem title="Commandes à livrer" valueDisplay={amazon.unshippedOrders || 0} onClick={() => this.toUnshippedLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Divider orientation="vertical" />
            </Grid>
              <Grid item xs={12} sm >
                <Stack direction="column" spacing={2}>
                  <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '0.9rem', sm: '0.9rem', md: '1.4rem' } }}>Produits Amazon</Typography>
                  <Grid container spacing={1} sx={{justifyContent:{xs:'center', sm:'flex-start'}}}>
                    <Grid item >{amazon ? <CardItem title="Produits en stock à mettre en vente" valueDisplay={amazon.toSellProduct || 0} onClick={() => this.toSellLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                    <Grid item >{amazon ? <CardItem title="Produits en stock à vérifier" valueDisplay={amazon.toVerifedProduct || 0} onClick={() => this.toVerifedLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                    <Grid item >{amazon ? <CardItem title="Produits en stock en matchs multiple" valueDisplay={amazon.multipleMatch || 0} onClick={() => this.toMultipleLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                    <Grid item >{amazon ? <CardItem title="Produits en attente" valueDisplay={amazon.pendingProduct || 0} onClick={() => this.toPendingLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                    <Grid item >{amazon ? <CardItem title="Produits dont la synchro est bloquée" valueDisplay={amazon.lockProduct || 0} onClick={() => this.toLockLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                  </Grid>
                </Stack>
              </Grid>
          </Grid>
        </Stack>

        <Divider sx={{marginTop: 3}} />

        <Stack mt={3} direction="column" spacing={5} >
          <Grid container spacing={5}>
            <Grid item xs={12} sm="auto">
              <Stack direction="column" spacing={2}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '0.9rem', sm: '0.9rem', md: '1.4rem' } }}>Commandes Card Market</Typography>
                <Grid container spacing={1} sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                  <Grid item >{cardmarket ? <CardItem title="Commandes à livrer" valueDisplay={/*cardmarket.unshippedOrders || 0*/ 0} onClick={/*() => this.mkmToUnshippedLink()*/ null} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={12} sm >
              <Stack direction="column" spacing={2}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '0.9rem', sm: '0.9rem', md: '1.4rem' } }}>Produits Card Market</Typography>
                <Grid container spacing={1} sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                  <Grid item >{cardmarket ? <CardItem title="Produits en stock à mettre en vente" valueDisplay={cardmarket.toSellProduct || 0} onClick={() => this.mkmToSellLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                  <Grid item >{cardmarket ? <CardItem title="Produits en stock à vérifier" valueDisplay={cardmarket.toVerifedProduct || 0} onClick={() => this.mkmToVerifedLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                  <Grid item >{cardmarket ? <CardItem title="Produits en stock en matchs multiple" valueDisplay={cardmarket.multipleMatch || 0} onClick={() => this.mkmToMultipleLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                  <Grid item >{cardmarket ? <CardItem title="Produits en attente" valueDisplay={cardmarket.pendingProduct || 0} onClick={() => this.mkmToPendingLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                  <Grid item >{cardmarket ? <CardItem title="Produits dont la synchro est bloquée" valueDisplay={cardmarket.lockProduct || 0} onClick={() => this.mkmToLockLink()} /> : <Skeleton variant="rounded" width={150} height={150} />}</Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
}

export default withStyles(styles)(Dashboard);
