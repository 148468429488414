import Theme from '../../../theme';

const style = {
  wrapper: {
    marginBottom: Theme.spacing(3),
  },
  visibilityMenu: {
    color: Theme.palette.black.light,
    '& div': {
      color: Theme.palette.black.light,
    }
  },
};
export default style; 

