import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import NotFound from '../../pages/NotFound';
import Login from '../../pages/Login';
import Dashboard from '../../pages/Dashboard';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import OrderAmazon from '../../pages/OrderAmazon';
import OrderMKM from '../../pages/OrderMKM';
import { ReactComponent as AmazonIcon } from '../../res/amazon-logo.svg';
import { ReactComponent as CardMarketIcon } from '../../res/carmarket-logo.svg';
import AmazonProducts from '../../pages/AmazonProducts';
import CardMarketProducts from '../../pages/CardMarketProducts';
import ExpansionMKM from '../../pages/ExpansionMKM';
import SingleMKM from '../../pages/SingleMKM';

export const MENU_KEY = {
  PAGE: '_page_',
  SUBPAGE: '_subpage_',
  CATEGORY: '_category_',
  SEPARATOR: '_separator_',
};

export const SEPARATOR_KEY = '_separator_';

/**
 * Node : le Component
 * route : routePath
 * exact : route exact => true / false
 * action : action => action requise pour afficher la page
 * label : Nom de la page dans l'onglet du navigateur
 */
const ConfigPage = {
  Home: { Node: Login, route: "/", exact: true },
  NotFound: { Node: NotFound, route: null, exact: false },
  Login: { Node: Login, route: "/login", exact: true },
  Dashboard: {
    Node: Dashboard,
    route: "/dashboard",
    exact: true,
    action: "markets",
    appMenu: true,
    showInMenu: true,
    label: "Tableau de bord",
    icon: <DashboardOutlinedIcon />,
  },
  OrderAmazon: {
    Node: OrderAmazon,
    route: "/order-amazon",
    exact: true,
    action: "markets",
    appMenu: true,
    showInMenu: true,
    label: "Commandes",
    icon: <FlightTakeoffIcon />,
  },
  AmazonProducts: {
    Node: AmazonProducts,
    route: '/products-amazon',
    exact: true,
    action: 'markets',
    appMenu: true,
    showInMenu: true,
    label: 'Produits',
    icon: <Inventory2Icon />,
  },
  OrderCardMarket: {
    Node: OrderMKM,
    route: "/order-mkm",
    exact: true,
    action: "markets",
    appMenu: true,
    showInMenu: true,
    label: "Commandes",
    icon: <FlightTakeoffIcon />,
  },
  CardMarketProducts: {
    Node: CardMarketProducts,
    route: '/products-mkm',
    exact: true,
    action: 'markets',
    appMenu: true,
    showInMenu: true,
    label: 'Produits Parkage',
    icon: <Inventory2Icon />,
  },
  ExpansionMKM: {
    Node: ExpansionMKM,
    route: '/expansion-mkm',
    exact: true,
    action: "markets",
    appMenu: true,
    showInMenu: true,
    label: "Extensions CardMarket",
    icon: <CardMarketIcon style={{ color: '#FFF', fill: '#FFF', width: 24, height: 24 }} />,
  },
  SingleMKM: {
    Node: SingleMKM,
    route: '/single-mkm',
    exact: true,
    action: "markets",
    appMenu: true,
    showInMenu: true,
    label: "BDD CardMarket",
    icon: <CardMarketIcon style={{ color: '#FFF', fill: '#FFF', width: 24, height: 24 }} />,
  }
};

export function getPage(pageName) {
  if (ConfigPage[pageName]) {
    return ConfigPage[pageName];
  }
  return null;
}

export const menuDrawerPageListOrder = [
  {
    type: MENU_KEY.PAGE,
    value: getPage('Dashboard')
  },
  { type: MENU_KEY.SEPARATOR },
  {
    label: 'Amazon',
    value: 'amazon',
    action: 'markets',
    type: MENU_KEY.CATEGORY,
    icon: <AmazonIcon style={{ color: '#FFF', fill: '#FFF', width: 24, height: 24 }} />,
    endSeparator: true,
    list: [
      getPage('OrderAmazon'),
      getPage('AmazonProducts'),
    ],
  },
  { type: MENU_KEY.SEPARATOR },
  {
    label: 'Card Market',
    value: 'cardmarket',
    action: 'markets',
    type: MENU_KEY.CATEGORY,
    icon: <CardMarketIcon style={{ color: '#FFF', fill: '#FFF', width: 24, height: 24 }} />,
    endSeparator: true,
    list: [
      // getPage('OrderCardMarket'),
      getPage('CardMarketProducts'),
      getPage('ExpansionMKM'),
      getPage('SingleMKM'),
    ],
  },
]

export default ConfigPage;