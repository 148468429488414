const styles = theme => ({
  mainWrapper: {
    height: '100%',
  },
  pageWrapper: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: '25px 25px 25px 104px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 10px 10px 65px',
    }
  },
  publicPageWrapper: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
  },
  notifyContainer: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    width: 'auto',
    zIndex: 10000,
  },
  asideContainer: {
    zIndex: 1000,
    paddingTop: 20,
    paddingBottom: 40,
    position:'fixed',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    overflow: 'hidden',
    left: 0,
    top: 0,
    color: theme.palette.white.main,
    transition: 'width 0.1s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent : 'space-between',
  },
  asideOpen: {
    width: 300,
    '& .listItemText': {
      opacity: 1,
    },
  },
  asideClose: {
    width: 50,
  },
  backgroundOverlay: {
    zIndex: 999,
    position: 'fixed',
    height: '100%',
    backgroundColor: theme.palette.black.light,
    opacity: 0.6,
  },
  backOpen: {
    width: '100%',
  },
  backClose: {
    width: 0,
  },
  menuItem: {
    opacity: 0.8,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.15) !important',
    },
  },
  listItemText: {
    opacity: 0,
    transition: 'opacity 0.1s',
    textTransform: 'uppercase',
    fontSize: '13px !important',
    padding: '10px 0',
    fontWeight: 700,
  },
});

export default styles;
