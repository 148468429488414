const theme = (theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
  },
});

export default theme;