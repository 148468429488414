const theme = (theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
  },
  ariane: {
    color: theme.palette.black.light,
    marginBottom: `${theme.spacing(1)} !important`,
    display: 'block',
    flexGrow: 0,
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  formContent: {
    padding: theme.spacing(4),
  },
  formControlStatus: {
    width: '100%', 
    verticalAlign: 'unset !important',
    '& .MuiOutlinedInput-notchedOutline': { 
      border: 0,
    }
  },
  wrapperSelector: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'flex-start',
  },
  imageSelector: {
    height: 80, 
    width: 50, 
    marginRight: 10,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  nameSelector: {
    marginRight: 10,
    width: 240,
  },
});

export default theme;