const styles = theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  gridWrapper: {
    width: 800,
    backgroundColor: theme.palette.primary.main, 
    borderRadius: 10,
    padding: theme.spacing(6),
    color: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    }
  },
  content: {
    minWidth: 256,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 240, 
  },
});

export default styles;
