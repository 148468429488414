import React from "react";
import moment from "moment";
import { withStyles } from "@mui/styles";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import Ajax from "../../../utils/Ajax/index.js";
import Notify from "../../../components/base/Notify/index.js";
import Grid from "../../../components/base/Grid/index.js";
import styles from "./styles.js";
import PropTypes from "prop-types";

class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      notFound: false,
    };
    this.refreshData = this.refreshData.bind(this);
  }

  async componentDidMount() {
    const { params } = this.props;
    const id = params.id ? params.id : "";
    const data = await this.getData(id);
    this.setState({ data: data || null, notFound: !data });
  }

  async getData(id) {
    const res = await Ajax.get({
      url: "api/order_amazon/" + id,
      token: true,
    });
    if (res.type === "success") {
      return res.item;
    } else {
      Notify.error(`NONE`);
      return false;
    }
  }

  async refreshData() {
    if (this.state.data) {
      const data = await this.getData(this.state.data.id);
      this.setState({ data });
    }
  }

  renderNotFound() {
    const { handleOpen, open } = this.props;
    return (
      <Dialog onClose={handleOpen} open={open}>
        <DialogContent>
          <Box
            p={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <WarningIcon sx={{ color: "error.main" }} />
            <Typography variant="body1" gutterBottom>
              Article Not Found
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderLoading() {
    const { handleOpen, open } = this.props;
    return (
      <Dialog onClose={handleOpen} open={open}>
        <DialogContent>Chargement...</DialogContent>
      </Dialog>
    );
  }

  renderContent() {
    const { data } = this.state;
    const { handleOpen, open } = this.props;
    const address_detail = data.amazon_address_detail;
    const date = data.amazon_last_update_date
      ? moment(data.amazon_last_update_date).format("LL")
      : "A définir.";
    return (
      <Dialog onClose={handleOpen} open={open}>
        <DialogTitle>
          <Typography variant="h4" component="h4" gutterBottom>
            {data.id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid spacing={3}>
            <Box key="1">
              <Typography variant="h3" component="h3" gutterBottom>
                Information générale
              </Typography>
              <Grid xs={6} spacing={0.1}>
                <Typography key="1">Amazon Order Id :</Typography>
                <Typography key="2" fontWeight={600}>
                  {data.amazon_order_id}
                </Typography>

                <Typography key="3">Status :</Typography>
                <Typography key="4" fontWeight={600}>
                  {data.amazon_status || ""}
                </Typography>

                <Typography key="5">Nombre d'item :</Typography>
                <Typography key="6" fontWeight={600}>
                  {data.amazon_number_items}
                </Typography>

                <Typography key="7">Méthode de livraison :</Typography>
                <Typography key="8" fontWeight={600}>
                  {data.amazon_shipment_method}
                </Typography>

                <Typography key="9">Last Update :</Typography>
                <Typography key="10" fontWeight={600}>
                  {date}
                </Typography>
                <Typography key="11">Prix Total:</Typography>
                <Typography key="12" fontWeight={600}>
                  {data.amazon_order_total}
                </Typography>
              </Grid>
            </Box>
            <Box key="2">
              <Typography variant="h3" component="h3" gutterBottom>
                Détail de livraison
              </Typography>
              <Grid xs={6} spacing={0.5}>
                <Typography key="1">Adresse:</Typography>
                <Typography fontWeight={600}>
                  {address_detail.address}
                </Typography>
                <Typography key="2">Ville:</Typography>
                <Typography fontWeight={600}>
                  {data.amazon_shipping_city}
                </Typography>
                <Typography key="3">Nom du Destinataire:</Typography>
                <Typography fontWeight={600}>{address_detail.name}</Typography>
                <Typography key="4">Téléphone du Destinataire:</Typography>
                <Typography fontWeight={600}>{address_detail.phone}</Typography>
              </Grid>
            </Box>
            <Box key="3">
              <Typography variant="h3" component="h3" gutterBottom>
                Détail du contenu
              </Typography>
              {data.amazon_items_detail?.map((item, index) => {
                return (
                  <>
                    {index > 0 && <Divider style={{marginBottom: '0.7rem', marginTop: '0.7rem'}} light />}
                    <Grid xs={6} spacing={0.5}>
                      <img src={item.item_image.link} style={{ width: 75, height: 75 }} alt={index} />
                      <Typography></Typography>
                      <Typography>Nom:</Typography>
                      <Typography fontWeight={600}>{item.item_name}</Typography>
                      <Typography>ASIN:</Typography>
                      <Typography fontWeight={600}>{item.item_asin}</Typography>
                      <Typography>SKU vendeur:</Typography>
                      <Typography fontWeight={600}>{item.item_sku}</Typography>
                      <Typography>Quantité:</Typography>
                      <Typography fontWeight={600}>
                        {item.item_quantity_order}
                      </Typography>
                      <Typography>Prix de l'article:</Typography>
                      <Typography fontWeight={600}>
                        {item.item_price} dont {item.item_tax} de taxe
                      </Typography>
                      {parseInt(item.item_discount) > 0 && (
                        <>
                          <Typography>Réduction sur l'article:</Typography>
                          <Typography fontWeight={600}>
                            {item.item_discount} dont {item.item_discount_tax}{" "}
                            de taxe
                          </Typography>
                        </>
                      )}
                      <Typography>Prix de l'envoi:</Typography>
                      <Typography fontWeight={600}>
                        {item.shipping_price} dont {item.shipping_tax} de taxe
                      </Typography>
                      {parseInt(item.shipping_discount) > 0 && (
                        <>
                          <Typography>Réduction sur l'article:</Typography>
                          <Typography fontWeight={600}>
                            {item.shipping_discount} dont{" "}
                            {item.shipping_discount_tax} de taxe
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const { data, notFound } = this.state;
    if (notFound) {
      return this.renderNotFound();
    }
    if (!data) {
      return this.renderLoading();
    }
    return this.renderContent();
  }
}

InfoModal.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(InfoModal);
