import React from 'react';
import isEqual from 'lodash/isEqual';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

class AutoSelect extends React.PureComponent {
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(evt, value) {
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const {
      name,
      value,
      label,
      placeholder,
      options,
      required,
      disabled,
      error,
      multiple,
      getOptionLabel,
      isOptionEqualToValue,
      onInputChange,
      loading,
      helperText,
      filterOptions,
      BoxProps,
      slotProps,
      onClose,
      renderOption
    } = this.props;
    const defaultValue = multiple ? [] : '';
    return (
      <Box {...BoxProps} >
        <Autocomplete
          slotProps={slotProps}
          onClose={onClose}
          filterOptions={filterOptions}
          size={multiple ? "small" : undefined}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          value={value || defaultValue}
          defaultValue={defaultValue}
          multiple={multiple}
          disabled={disabled}
          renderInput={(params) => {
            if (onInputChange) {
              const localInputChange = params.inputProps.onChange;
              params.inputProps.onChange = (evt) => {
                localInputChange(evt);
                onInputChange(evt);
              };
            }
            return(
            <TextField
              {...params}
              variant="standard"
              label={label || name}
              placeholder={placeholder || ''}
              required={required}
              error={error}
              disabled={disabled}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={8} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );}}
          renderOption={renderOption ? renderOption : (props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {getOptionLabel(option)}
            </li>
          )}
          onChange={this.handleChange}
          disableCloseOnSelect={multiple}
          fullWidth
        />
      </Box>
    );
  }
}

AutoSelect.defaultProps = {
  value: null,
  label: '',
  placeholder: '',
  multiple: false,
  required: false,
  disabled: false,
  error: false,
  getOptionLabel: option => option,
  isOptionEqualToValue: (option, value) => isEqual(option, value),
}

AutoSelect.propTypes = {
  // Main
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  // Options
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  slotProps: PropTypes.object,
  // Functions
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  // Box
  BoxProps: PropTypes.object,
}

export default AutoSelect;