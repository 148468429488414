import React from 'react';
import PropTypes from 'prop-types';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { menuDrawerPageListOrder, MENU_KEY } from '../Router/path';

import Confirm from '../../components/base/Confirm';
import Device from '../../utils/Device';

import styles from './styles.js';
import { Divider, List, ListItemButton, ListItemText } from '@mui/material';

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openConfirmLogout: false,
      categoryOpenList: [],
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onLogoutBtn = this.onLogoutBtn.bind(this);
    this.onCancelLogout = this.onCancelLogout.bind(this);
    this.onValidateLogout = this.onValidateLogout.bind(this);
  }

  handleToggleCategory(evt, category) {
    if (!!Device.mobile() && !this.state.open) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    let categoryOpenList = [];
    this.setState(prev => {
      categoryOpenList = [...prev.categoryOpenList];
      const catIdx = categoryOpenList.findIndex(c => c === category);
      if (catIdx !== -1) {
        categoryOpenList.splice(catIdx, 1);
      } else {
        categoryOpenList.push(category);
      }
      return { categoryOpenList };
    });
  }

  onMouseEnter() {
    if(!Device.mobile()) {
      this.setState({ open: true });
    }
  }

  onMouseLeave() {
    if (!Device.mobile()) {
      this.setState({ open: false });
    }
  }

  onClick() {
    if (!!Device.mobile()) {
      this.setState(prev => ({open: !prev.open}));
    }
  }

  onLogoutBtn() {
    if (!!Device.mobile() && !this.state.open) {
      return;
    }
    this.setState({ openConfirmLogout: true });
  }

  onCancelLogout() {
    this.setState({ openConfirmLogout: false });
  }

  onValidateLogout() {
    const { onLogout } = this.props;
    this.setState({ openConfirmLogout: false }, onLogout);
  }

  onMenuItem(conf, evt) {
    if (!!Device.mobile() && !this.state.open) {
      return;
    }
    if(!evt) {
      const { redirect } = this.props;
      redirect(conf.route);
    } else if(evt.button === 1) {
      window.open(conf.route);
    }
  }

  renderConfirmLogout() {
    const { openConfirmLogout } = this.state;
    if (!openConfirmLogout) {
      return null;
    }
    return (
      <Confirm
        open={openConfirmLogout}
        onClickAway={this.onCancelLogout}
        onValidate={this.onValidateLogout}
        title="Déconnexion"
        message="Êtes vous sûr de vouloir vous déconnecter ?"
        cancel="Annuler"
        validate="Se déconnecter"
      />
    );
  }
  /*
  
  
  */
  renderMenu() {
    const { categoryOpenList, open } = this.state;
    const { user } = this.props;
    return (
      <div key={Math.random() * 100}>
        {menuDrawerPageListOrder.map((param, index) => {
          if (param.type === MENU_KEY.SEPARATOR) {
            return <Divider key={`divider_${index}`} />;
          }
          if (param.type === MENU_KEY.PAGE) {
            if (param.value.showInMenu && user?.actions && user?.actions[param.value.action]) {
              return (
                <MenuItem 
                  key={param.value.route} 
                  onClick={() => this.onMenuItem(param.value)}
                  onMouseUp={evt => this.onMenuItem(param.value, evt)}
                  >
                  {param.value.icon && (
                    <ListItemIcon>
                      {param.value.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText primary={param.value?.label} />
                </MenuItem>
              );
            }
          }
          if (param.type === MENU_KEY.CATEGORY) {
            const catOpen = categoryOpenList.includes(param.value) && open;
            return (
              <div key={param.value}>
                <MenuItem key={param.value} onClick={evt => this.handleToggleCategory(evt, param.value)}>
                  <ListItemIcon>
                    {param.icon}
                  </ListItemIcon>
                  <ListItemText primary={param.label} />
                  {catOpen ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>
                <Collapse key={`${param.value}_${index}`} in={catOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {param.list.map(page => {
                      if (page.showInMenu && user?.actions && user?.actions[page.action]) {
                        return (
                          <ListItemButton
                            sx={{ pl: 6.5 }}
                            key={page.route}
                            onClick={() => this.onMenuItem(page)}
                            onMouseUp={evt => this.onMenuItem(page, evt)}
                          >
                            <ListItemText primary={page?.label} />
                          </ListItemButton>
                        );
                      } else
                        return null
                    })}
                  </List>
                </Collapse>
                {param.endSeparator && <Divider key={`divider_${index}`} />}
              </div>
            );
          }
          return <></>
        })
        }
      </div>
    );
  }

  renderOptions() {
    const { classes } = this.props;
    return (
      <MenuItem key="logout" className={classes.menuItem} onClick={this.onLogoutBtn}>
        <ListItemIcon>
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <span className={`${classes.listItemText} listItemText`}>
          Déconnexion
        </span>
      </MenuItem>
    );
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const openClass = open ? classes.asideOpen : classes.asideClose;
    const backOpen = open ? classes.backOpen : classes.backClose;
    return (
      <>
        <aside 
          className={`${classes.asideContainer} ${openClass}`} 
          onMouseEnter={this.onMouseEnter} 
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
        >
          <MenuList>
            {this.renderMenu()}
          </MenuList>
          <MenuList>
            {this.renderOptions()}
          </MenuList>
        </aside>
        { !!Device.mobile() ? (<div className={`${classes.backgroundOverlay} ${backOpen}`} onClick={this.onClick}></div>) : null}
        {this.renderConfirmLogout()}
      </>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  redirect: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default withStyles(styles)(AppBar);