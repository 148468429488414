import  React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function RobotCotCot(props) {
  return (
    <SvgIcon {...props}>

      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill='currentColor'>

        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
          stroke="none">
          <path d="M660 1266 c-14 -8 -32 -26 -40 -40 -11 -22 -20 -26 -58 -26 -37 0
-50 -6 -78 -34 -28 -28 -34 -42 -34 -76 0 -23 4 -50 10 -60 8 -15 4 -22 -25
-40 -49 -30 -120 -112 -150 -174 -44 -89 -55 -150 -55 -299 0 -160 10 -191 84
-253 25 -20 47 -48 51 -62 11 -46 47 -84 101 -109 30 -13 57 -30 61 -37 14
-24 77 -56 111 -56 38 0 79 19 105 48 10 11 43 32 73 47 55 27 88 62 99 107 4
14 26 42 51 62 74 62 84 93 84 255 0 120 -3 151 -24 216 -13 41 -38 97 -55
124 -35 55 -107 125 -146 143 -29 13 -30 19 -9 60 40 78 36 133 -15 182 -35
34 -102 44 -141 22z m104 -99 c8 -21 5 -37 -12 -72 l-22 -45 -79 0 c-44 0 -86
-3 -94 -6 -10 -3 -20 3 -27 16 -25 48 28 93 61 51 12 -14 29 -21 53 -21 l36 0
0 48 c0 58 5 64 44 60 23 -2 33 -10 40 -31z m1 -220 c28 -14 72 -48 98 -76 84
-89 117 -195 117 -370 0 -120 -15 -156 -82 -191 -42 -22 -48 -29 -48 -56 0
-33 -33 -84 -54 -84 -9 0 -16 21 -20 59 -4 32 -15 74 -25 94 l-18 35 39 50
c35 45 38 52 26 71 -7 12 -31 34 -52 49 -33 23 -50 27 -105 27 -55 0 -72 -5
-104 -27 -21 -14 -46 -36 -54 -48 -14 -20 -12 -24 24 -72 l40 -50 -18 -35
c-10 -20 -21 -62 -25 -94 -4 -38 -11 -59 -20 -59 -21 0 -54 51 -54 84 0 27 -6
34 -48 56 -68 36 -82 70 -82 197 0 56 5 126 11 155 32 150 130 267 259 309 45
15 142 3 195 -24z m-71 -473 c24 -9 19 -25 -19 -68 l-35 -39 -35 39 c-37 42
-43 59 -22 67 18 8 93 8 111 1z m-70 -206 l16 -23 16 23 c24 32 31 28 43 -28
24 -113 7 -160 -59 -160 -66 0 -83 47 -59 160 12 56 19 60 43 28z"/>
          <path d="M470 715 c0 -34 1 -35 40 -35 39 0 40 1 40 35 0 34 -1 35 -40 35 -39
0 -40 -1 -40 -35z"/>
          <path d="M730 715 c0 -34 1 -35 40 -35 39 0 40 1 40 35 0 34 -1 35 -40 35 -39
0 -40 -1 -40 -35z"/>
        </g>
      </svg>
    </SvgIcon>
  );
}