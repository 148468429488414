import React from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import Alert from '@mui/material/Alert';

const DEFAULT_OPTIONS = { delay: 5 };

class Notify extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      alertMap: {},
    };
    Notify.instance = this;
  }

  onClose(key) {
    this.setState(prevState => ({ alertMap: omit(prevState.alertMap, [key]) }));
  }

  addAlert(type, content, opts = {}) {
    const options = { ...DEFAULT_OPTIONS, ...opts };
    const key = uniqueId('alert_');
    if (options.delay > 0) {
      setTimeout(() => this.onClose(key), options.delay * 1000);
    }
    this.setState(prevState => ({ alertMap: { ...prevState.alertMap, [key]: { type, content, options } }}));
    return key;
  }

  render() {
    const { alertMap } = this.state;
    return (
      <React.Fragment>
        {
          map(alertMap, ({ type, content }, key) => {
            return (
              <Alert key={key} severity={type} variant="filled" elevation={6} onClose={() => this.onClose(key)} >
                {content}
              </Alert>
            );
          })
        }
      </React.Fragment>
    );
  }
}

Notify.propTypes = {};

Notify.instance = null;

Notify.error = (content, options) => {
  if (Notify.instance) {
    return Notify.instance.addAlert('error', content, options);
  }
  return null;
};

Notify.warning = (content, options) => {
  if (Notify.instance) {
    return Notify.instance.addAlert('warning', content, options);
  }
  return null;
};

Notify.info = (content, options) => {
  if (Notify.instance) {
    return Notify.instance.addAlert('info', content, options);
  }
  return null;
};

Notify.success = (content, options) => {
  if (Notify.instance) {
    return Notify.instance.addAlert('success', content, options);
  }
  return null;
};

Notify.close = (key) => {
  if (Notify.instance) {
    Notify.instance.onClose(key);
  }
  return null;
};

export default Notify;
