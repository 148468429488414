const style = theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    paddingBottom: theme.spacing(1),
  },
  ariane: {
    color: theme.palette.black.light,
    marginBottom: `${theme.spacing(1)} !important`,
    display: 'block',
    flexGrow: 0,
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
});

export default style;
