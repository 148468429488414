import React from 'react';
import isNil from 'lodash/isNil';
import moment from 'moment';

import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Theme from '../../theme';
import Config from '../../config';
import { Stack } from '@mui/material';

export function dateEnToDateFr({ value }) {
  if (isNil(value)) {
    return '';
  }
  return moment.utc(value).local().format('DD/MM/YYYY');
}

export function dateTimeEnToDateTimeFr({ value }) {
  if (isNil(value)) {
    return '';
  }
  return moment.utc(value).local().format('DD/MM/YYYY HH:mm:ss');
}

export function booleanFormatter({ value }) {
  return !!value ? 'Oui' : 'Non';
}

export function euroFormatter({ value }) {
  const number = parseFloat(value, 10);
  if (!isNaN(number)) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number);
  }
  return value;
}

export function percentFormatter({ value }) {
  let number = parseFloat(value, 10);
  if (!isNaN(number)) {
    if (number > 1) {
      number = number / 100;
    }
    return number.toLocaleString("fr", { style: "percent" });
  }
  return value;
}

export function arrayFormatter({ value }) {
  return Array.isArray(value) ? value.join(', ') : value;
}

export function linkFormatter({ value, formattedValue }) {
  return (
    <a href={value} target="_blank" style={{ color: 'inherit' }} rel="noreferrer" >{formattedValue || value}</a>
  );
}

export function renderDesignationCellLite({ row, formattedValue }) {
  const productName = formattedValue;
  const style = row.batch > 0 && row.batch !== '0' ? { color: Theme.palette.primary.main, fontWeight: 'bold' } : {};
  const title = row.batch > 0 && row.batch !== '0' ? `[COMPOSITE] ${productName}` : productName;
  style.lineHeight = 1;
  return (
    <Stack direction="column">
      <span style={style} title={title}>
        {row.is_foil ? <span title='Foil' style={{ color: '#F2B41B' }}>★ </span> : ''}
        {productName}
      </span>
      {row.name_en ? <div title={row.name_en} style={{ fontSize: 12, lineHeight: 1 }}>{row.name_en}</div >: null}
    </Stack>
  );
}

export function renderPreviewCell({ row, value }) {
  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0.5,
  };
  if (!value) {
    return (
      <Box sx={style} title="Aucun aperçu">
        <NoPhotographyIcon color="disabled" />
      </Box>
    )
  }
  const url = value.indexOf('http') !== -1 ? value : `${Config.PARKAGE_PRODUCT_IMG_URL}${value}`;
  return (
    <Button
      sx={style}
      title="Voir l'image dans un nouvel onglet"
      href={url}
      target="_blank"
      component="a"
      variant="text"
      color="inherit"
      onClick={evt => {
        // evt.preventDefault();
        evt.stopPropagation();
      }}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '10%' }} src={url} alt="" />
    </Button>
  )
}

const formatters = {
  dateEnToDateFr,
  dateTimeEnToDateTimeFr,
  booleanFormatter,
  euroFormatter,
  percentFormatter,
  arrayFormatter,
  linkFormatter,
  renderDesignationCellLite,
  renderPreviewCell
};

export default formatters;
